<template>
  <div class="modal modal-critical">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            Соединение прервано, повторное подключение...
            <button v-on:click="setLocalCookie(true); refreshPage();" v-if="store.state.custom.userRole === 'OperationalScreen'">Локально</button>
            <button v-on:click="setLocalCookie(false); refreshPage();" v-if="store.state.custom.userRole === 'OperationalScreen'">Не Локально</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup lang="ts">
import { useStore } from '@/generated/heplers/useStore';

const store = useStore();

function setLocalCookie (local: boolean) {
  let value = local ? '1' : '0';
  document.cookie = 'local' + '=' + value + '; path=/';
}

function refreshPage () {
  document.location.reload();
}
</script>

<style lang="scss">
</style>
