<template>
  <template v-if="!store.state.custom.isAuthenticated">
    <button v-on:click="setLocalCookie(true); refreshPage();" v-if="store.state.custom.userRole === 'OperationalScreen'">Локально</button>
    <button v-on:click="setLocalCookie(false); refreshPage();" v-if="store.state.custom.userRole === 'OperationalScreen'">Не Локально</button>
    <input v-model="loginInput" placeholder="Логин">
    <input v-model="passInput" placeholder="Пароль" type="password">
    <button v-on:click="$RpcClient.login(loginInput, passInput, protocolVersion); loginInput = ''; passInput = '';">Войти</button>
  </template>
  <template v-if="store.state.custom.isAuthenticated">
    <span>В системе как: {{ store.state.custom.userName }} </span>
    <button v-on:click="$RpcClient.logout()">Выйти</button>
  </template>
</template>

<script setup lang="ts">
import { useStore } from '@/generated/heplers/useStore';
import { ref } from 'vue';
import { protocolVersion } from '@/generated/api'

const store = useStore();
let loginInput = ref('');
let passInput = ref('');

function setLocalCookie (local: boolean) {
  let value = local ? '1' : '0';
  document.cookie = 'local' + '=' + value + '; path=/';
}

function refreshPage () {
  document.location.reload();
}
</script>
