import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/workshift',
    name: 'Workshift',
    component: () => import('../views/Workshift.vue')
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: () => import('../views/Tasks.vue')
  },
  {
    path: '/unpredicted-tasks',
    name: 'UnpredictedTasks',
    component: () => import('../views/UnpredictedTasks.vue')
  },
  {
    path: '/personal-page',
    name: 'PersonalPage',
    component: () => import('../views/PersonalPage.vue')
  },
  {
    path: '/personal-page/accounting',
    name: 'PersonalAccounting',
    component: () => import('../views/PersonalAccounting.vue')
  },
  {
    path: '/billet-tasks',
    name: 'BilletTasks',
    component: () => import('../views/BilletTasks.vue')
  },
  {
    path: '/tasks-approval',
    name: 'TasksApproval',
    component: () => import('../views/TasksApproval.vue')
  },
  {
    path: '/accounting',
    name: 'Accounting',
    component: () => import('../views/Accounting.vue')
  },
  {
    path: '/workers',
    name: 'Workers',
    component: () => import('../views/Workers.vue')
  }
]

const router = createRouter({
  history: createWebHistory('/'),
  routes
})

export default router
