<template>
  <head>
    <meta charset="utf-8">
  </head>
  <body>
    <transition name="modal">
      <ReconnectModal v-if="!store.state.custom.isConnected" />
    </transition>
    <div v-if="store.state.custom.isAuthenticated" id="nav">
      <router-link to="/">
        Логин
      </router-link> |
      <router-link to="/personal-page" v-if="!(store.state.custom.userRole === 'OperationalScreen')">
        ЛК
      </router-link> |
      <router-link to="/workshift">
        Смены
      </router-link> |
      <router-link to="/tasks">
        Задачи
      </router-link> |
      <router-link to="/unpredicted-tasks">
        Непредвиденные задачи
      </router-link> |
      <router-link to="/billet-tasks" v-if="store.state.custom.userRole === 'Manager' || store.state.custom.userRole === 'Executive'">
        Заготовочные задачи
      </router-link> |
      <router-link to="/tasks-approval">
        Одобрение задач
      </router-link> |
      <router-link to="/accounting" v-if="store.state.custom.userRole === 'Executive'">
        Расчеты
      </router-link> |
      <router-link to="/workers" v-if="store.state.custom.userRole === 'Executive'">
        Пользователи
      </router-link>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </body>
</template>

<script setup lang="ts">
import ReconnectModal from './modals/ReconnectModal.vue';
import { useStore } from '@/generated/heplers/useStore';
const store = useStore();
</script>

<style lang="scss">
@import "globalStyles.scss";

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 8pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 10px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
