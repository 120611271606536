import WebSocketAsPromised from 'websocket-as-promised'

export class WebSocketRpc extends WebSocketAsPromised {
  requestIdMap: Map<number, string | number> = new Map()

  constructor (url: string) {
    super(url, {
      packMessage: data => JSON.stringify(data),
      // @ts-expect-error: sure about type
      unpackMessage: data => JSON.parse(data),
      attachRequestId: (data, requestId) => {
        let idGenerated = false;
        let generatedId = 0;
        while (!idGenerated) {
          generatedId = Math.floor(Math.random() * 1000);
          if (!this.requestIdMap.has(generatedId)) {
            this.requestIdMap.set(generatedId, requestId);
            idGenerated = true;
          }
        }
        return Object.assign({ requestId: generatedId }, data);
      },
      extractRequestId: data => {
        const requestId = this.requestIdMap.get(data.requestId);
        this.requestIdMap.delete(data.requestId);
        return requestId;
      }
    })
  }
}
