import { Store, createStore as baseCreateStore } from 'vuex'
import * as API from './api'
import { RpcClient, UpdateRowPayload, AbstractModel_Remote } from './heplers/apiAbstract'
import WebSocketAsPromised from 'websocket-as-promised'

export interface AbstractModelRow{
  [s: string]: any
}

export interface worker extends AbstractModelRow { name_ru: string, salary_rate_per_hour: number, factory_force: number, shift_opened: boolean, wage_arrears: number, pass: string, enabled: boolean, outsource: boolean, role: string, name: string }
export interface order extends AbstractModelRow { payed_amount: number, require_custom_revision: boolean, drawings_notes: string, custom_revision_notes: string, stock_id: number, multiple_items_order: boolean, map_type: string, wood: string, start_time: Date, end_time: Date, client_name: string, first_item_id: number, client_phone: string, wood_from_stock_id: number, shipment_type: number, actual_end_time: Date, address: string, items: string, cost_without_shipment: number, tasks: string, cost: number, notes: string, at_workshop: boolean, require_print: boolean, chopped: boolean, require_light: boolean, soldered: boolean, job_accepted: boolean, id: number, consignment: number, require_drawings: boolean, require_factory: boolean, half_payment: boolean, require_consignment: boolean, archive: boolean, require_top_engrave: boolean }
export interface task extends AbstractModelRow { available: boolean, current_real_time_duration_sec: number, real_time_duration_sec: number, irrelevant_standard_task: boolean, current_worker_names: string, floating_time: boolean, id: number, action: string, description: string, completed: boolean, duration_sec: number, proposed_duration_sec: number, average_duration_sec: number, action_ru: string, workforce_multiplier: number, strict_time: boolean, creation_time: Date, priority_floor: number, priority_ceiling: number, items: string, disabled_task: boolean, standard_task: boolean, unexpected_task: boolean, unexpected_task_approval: boolean, in_progress: boolean, completed_time: Date, worker_names: string, unexpected_task_checked: boolean, lost_task: boolean, current_time_speed: number, current_duration_sec: number, require_approval: boolean }
export interface actionType extends AbstractModelRow { action_ru: string, workforce_multiplier: number, strict_time: boolean, priority_floor: number, priority_ceiling: number, expanding_task: boolean, wooden_sheet_task: boolean, disabled_task: boolean, standard_task: boolean, require_approval: boolean, floating_time: boolean, billet_task: boolean, action: string }
export interface stock extends AbstractModelRow { wood: string, end_time: Date, notes: string, require_print: boolean, require_top_engrave: boolean, archive: boolean, id: number, ready: boolean, start_time: Date, chopped: boolean, soldered: boolean, require_light: boolean, wood_from_stock_id: number, map_type: string }
export interface averageDuration extends AbstractModelRow { map_type: string, average_duration_sec: number, leaderboard: string, action: string }
export interface workerStatisticks extends AbstractModelRow { period_start: Date, period_end: Date, profit_sec: number, payed_time_sec: number, period_wage: number, tasks: string, period_completed: boolean, salary_rate_per_hour: number, corrections: string, salary_multiplier: number, contribution_sec: number, workforce: number, real_contribution_sec: number, name: string }
export interface accountingMark extends AbstractModelRow { account_type: string, income: number, balance: number, account_name: string, description: string, time: Date }
export interface State {
  models: {
    Workers?: Array<worker>
Orders?: Array<order>
Tasks?: Array<task>
ActionTypes?: Array<actionType>
Stock?: Array<stock>
AverageDurations?: Array<averageDuration>
WorkerStatisticks?: Array<workerStatisticks>
AccountingMarks?: Array<accountingMark>
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// role: Worker -> column: name, role: Manager -> column: name
export class WorkersModel_Remote extends API.WorkersModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Workers: Array<worker>} {
    return { Workers: [] };
  }

  getMutations () {
    const mutations = {
      add_worker (state: State, payload: worker) {
        state.models.Workers!.push({ name_ru: payload.name_ru, salary_rate_per_hour: payload.salary_rate_per_hour, factory_force: payload.factory_force, shift_opened: payload.shift_opened, wage_arrears: payload.wage_arrears, pass: payload.pass, enabled: payload.enabled, outsource: payload.outsource, role: payload.role, name: payload.name });
      },
      update_worker (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let worker of state.models.Workers!) {
          for (let indexPart of payload.index) {
            let checkValue = worker[indexPart.role];
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          worker[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_worker (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.Workers!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.Workers![i][indexPart.role];
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.Workers!.splice(i, 1);
          break;
        }
      },
      remove_all_worker (state: State) {
        state.models.Workers!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: worker): void {
    this.#store.commit('add_worker', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_worker', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_worker', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_worker');
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export class OrdersModel_Remote extends API.OrdersModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Orders: Array<order>} {
    return { Orders: [] };
  }

  getMutations () {
    const mutations = {
      add_order (state: State, payload: order) {
        state.models.Orders!.push({ payed_amount: payload.payed_amount, require_custom_revision: payload.require_custom_revision, drawings_notes: payload.drawings_notes, custom_revision_notes: payload.custom_revision_notes, stock_id: payload.stock_id, multiple_items_order: payload.multiple_items_order, map_type: payload.map_type, wood: payload.wood, start_time: new Date(payload.start_time), end_time: new Date(payload.end_time), client_name: payload.client_name, first_item_id: payload.first_item_id, client_phone: payload.client_phone, wood_from_stock_id: payload.wood_from_stock_id, shipment_type: payload.shipment_type, actual_end_time: new Date(payload.actual_end_time), address: payload.address, items: payload.items, cost_without_shipment: payload.cost_without_shipment, tasks: payload.tasks, cost: payload.cost, notes: payload.notes, at_workshop: payload.at_workshop, require_print: payload.require_print, chopped: payload.chopped, require_light: payload.require_light, soldered: payload.soldered, job_accepted: payload.job_accepted, id: payload.id, consignment: payload.consignment, require_drawings: payload.require_drawings, require_factory: payload.require_factory, half_payment: payload.half_payment, require_consignment: payload.require_consignment, archive: payload.archive, require_top_engrave: payload.require_top_engrave });
      },
      update_order (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let order of state.models.Orders!) {
          for (let indexPart of payload.index) {
            let checkValue = order[indexPart.role];
            if (indexPart.role === 'start_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'end_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'actual_end_time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          if (payload.changedDataRoleName === 'start_time') value = new Date(value);
          if (payload.changedDataRoleName === 'end_time') value = new Date(value);
          if (payload.changedDataRoleName === 'actual_end_time') value = new Date(value);
          order[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_order (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.Orders!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.Orders![i][indexPart.role];
            if (indexPart.role === 'start_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'end_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'actual_end_time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.Orders!.splice(i, 1);
          break;
        }
      },
      remove_all_order (state: State) {
        state.models.Orders!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: order): void {
    this.#store.commit('add_order', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_order', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_order', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_order');
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export class TasksModel_Remote extends API.TasksModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Tasks: Array<task>} {
    return { Tasks: [] };
  }

  getMutations () {
    const mutations = {
      add_task (state: State, payload: task) {
        state.models.Tasks!.push({ available: payload.available, current_real_time_duration_sec: payload.current_real_time_duration_sec, real_time_duration_sec: payload.real_time_duration_sec, irrelevant_standard_task: payload.irrelevant_standard_task, current_worker_names: payload.current_worker_names, floating_time: payload.floating_time, id: payload.id, action: payload.action, description: payload.description, completed: payload.completed, duration_sec: payload.duration_sec, proposed_duration_sec: payload.proposed_duration_sec, average_duration_sec: payload.average_duration_sec, action_ru: payload.action_ru, workforce_multiplier: payload.workforce_multiplier, strict_time: payload.strict_time, creation_time: new Date(payload.creation_time), priority_floor: payload.priority_floor, priority_ceiling: payload.priority_ceiling, items: payload.items, disabled_task: payload.disabled_task, standard_task: payload.standard_task, unexpected_task: payload.unexpected_task, unexpected_task_approval: payload.unexpected_task_approval, in_progress: payload.in_progress, completed_time: new Date(payload.completed_time), worker_names: payload.worker_names, unexpected_task_checked: payload.unexpected_task_checked, lost_task: payload.lost_task, current_time_speed: payload.current_time_speed, current_duration_sec: payload.current_duration_sec, require_approval: payload.require_approval });
      },
      update_task (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let task of state.models.Tasks!) {
          for (let indexPart of payload.index) {
            let checkValue = task[indexPart.role];
            if (indexPart.role === 'creation_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'completed_time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          if (payload.changedDataRoleName === 'creation_time') value = new Date(value);
          if (payload.changedDataRoleName === 'completed_time') value = new Date(value);
          task[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_task (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.Tasks!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.Tasks![i][indexPart.role];
            if (indexPart.role === 'creation_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'completed_time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.Tasks!.splice(i, 1);
          break;
        }
      },
      remove_all_task (state: State) {
        state.models.Tasks!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: task): void {
    this.#store.commit('add_task', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_task', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_task', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_task');
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export class ActionTypesModel_Remote extends API.ActionTypesModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {ActionTypes: Array<actionType>} {
    return { ActionTypes: [] };
  }

  getMutations () {
    const mutations = {
      add_actionType (state: State, payload: actionType) {
        state.models.ActionTypes!.push({ action_ru: payload.action_ru, workforce_multiplier: payload.workforce_multiplier, strict_time: payload.strict_time, priority_floor: payload.priority_floor, priority_ceiling: payload.priority_ceiling, expanding_task: payload.expanding_task, wooden_sheet_task: payload.wooden_sheet_task, disabled_task: payload.disabled_task, standard_task: payload.standard_task, require_approval: payload.require_approval, floating_time: payload.floating_time, billet_task: payload.billet_task, action: payload.action });
      },
      update_actionType (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let actionType of state.models.ActionTypes!) {
          for (let indexPart of payload.index) {
            let checkValue = actionType[indexPart.role];
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          actionType[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_actionType (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.ActionTypes!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.ActionTypes![i][indexPart.role];
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.ActionTypes!.splice(i, 1);
          break;
        }
      },
      remove_all_actionType (state: State) {
        state.models.ActionTypes!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: actionType): void {
    this.#store.commit('add_actionType', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_actionType', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_actionType', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_actionType');
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export class StockModel_Remote extends API.StockModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {Stock: Array<stock>} {
    return { Stock: [] };
  }

  getMutations () {
    const mutations = {
      add_stock (state: State, payload: stock) {
        state.models.Stock!.push({ wood: payload.wood, end_time: new Date(payload.end_time), notes: payload.notes, require_print: payload.require_print, require_top_engrave: payload.require_top_engrave, archive: payload.archive, id: payload.id, ready: payload.ready, start_time: new Date(payload.start_time), chopped: payload.chopped, soldered: payload.soldered, require_light: payload.require_light, wood_from_stock_id: payload.wood_from_stock_id, map_type: payload.map_type });
      },
      update_stock (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let stock of state.models.Stock!) {
          for (let indexPart of payload.index) {
            let checkValue = stock[indexPart.role];
            if (indexPart.role === 'end_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'start_time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          if (payload.changedDataRoleName === 'end_time') value = new Date(value);
          if (payload.changedDataRoleName === 'start_time') value = new Date(value);
          stock[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_stock (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.Stock!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.Stock![i][indexPart.role];
            if (indexPart.role === 'end_time') checkValue = checkValue.getTime();
            if (indexPart.role === 'start_time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.Stock!.splice(i, 1);
          break;
        }
      },
      remove_all_stock (state: State) {
        state.models.Stock!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: stock): void {
    this.#store.commit('add_stock', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_stock', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_stock', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_stock');
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export class AverageDurationsModel_Remote extends API.AverageDurationsModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {AverageDurations: Array<averageDuration>} {
    return { AverageDurations: [] };
  }

  getMutations () {
    const mutations = {
      add_averageDuration (state: State, payload: averageDuration) {
        state.models.AverageDurations!.push({ map_type: payload.map_type, average_duration_sec: payload.average_duration_sec, leaderboard: payload.leaderboard, action: payload.action });
      },
      update_averageDuration (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let averageDuration of state.models.AverageDurations!) {
          for (let indexPart of payload.index) {
            let checkValue = averageDuration[indexPart.role];
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          averageDuration[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_averageDuration (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.AverageDurations!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.AverageDurations![i][indexPart.role];
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.AverageDurations!.splice(i, 1);
          break;
        }
      },
      remove_all_averageDuration (state: State) {
        state.models.AverageDurations!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: averageDuration): void {
    this.#store.commit('add_averageDuration', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_averageDuration', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_averageDuration', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_averageDuration');
  }
}

// Allowed roles:
// Worker, Manager, Executive
// Row restriction based on role and user name:
// role: Worker -> column: name, role: Manager -> column: name
export class WorkerStatisticksModel_Remote extends API.WorkerStatisticksModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {WorkerStatisticks: Array<workerStatisticks>} {
    return { WorkerStatisticks: [] };
  }

  getMutations () {
    const mutations = {
      add_workerStatisticks (state: State, payload: workerStatisticks) {
        state.models.WorkerStatisticks!.push({ period_start: new Date(payload.period_start), period_end: new Date(payload.period_end), profit_sec: payload.profit_sec, payed_time_sec: payload.payed_time_sec, period_wage: payload.period_wage, tasks: payload.tasks, period_completed: payload.period_completed, salary_rate_per_hour: payload.salary_rate_per_hour, corrections: payload.corrections, salary_multiplier: payload.salary_multiplier, contribution_sec: payload.contribution_sec, workforce: payload.workforce, real_contribution_sec: payload.real_contribution_sec, name: payload.name });
      },
      update_workerStatisticks (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let workerStatisticks of state.models.WorkerStatisticks!) {
          for (let indexPart of payload.index) {
            let checkValue = workerStatisticks[indexPart.role];
            if (indexPart.role === 'period_start') checkValue = checkValue.getTime();
            if (indexPart.role === 'period_end') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          if (payload.changedDataRoleName === 'period_start') value = new Date(value);
          if (payload.changedDataRoleName === 'period_end') value = new Date(value);
          workerStatisticks[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_workerStatisticks (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.WorkerStatisticks!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.WorkerStatisticks![i][indexPart.role];
            if (indexPart.role === 'period_start') checkValue = checkValue.getTime();
            if (indexPart.role === 'period_end') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.WorkerStatisticks!.splice(i, 1);
          break;
        }
      },
      remove_all_workerStatisticks (state: State) {
        state.models.WorkerStatisticks!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: workerStatisticks): void {
    this.#store.commit('add_workerStatisticks', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_workerStatisticks', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_workerStatisticks', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_workerStatisticks');
  }
}

// Allowed roles:
// Worker, Manager, Executive
// Row restriction based on role and user name:
// role: Worker -> column: account_name, role: Manager -> column: account_name
export class AccountingMarksModel_Remote extends API.AccountingMarksModel_Remote {
  #store!: Store<State>

  setStore (store: Store<State>): void {
    this.#store = store;
  }

  getState () : {AccountingMarks: Array<accountingMark>} {
    return { AccountingMarks: [] };
  }

  getMutations () {
    const mutations = {
      add_accountingMark (state: State, payload: accountingMark) {
        state.models.AccountingMarks!.push({ account_type: payload.account_type, income: payload.income, balance: payload.balance, account_name: payload.account_name, description: payload.description, time: new Date(payload.time) });
      },
      update_accountingMark (state: State, payload: UpdateRowPayload) {
        rowIterator: for (let accountingMark of state.models.AccountingMarks!) {
          for (let indexPart of payload.index) {
            let checkValue = accountingMark[indexPart.role];
            if (indexPart.role === 'time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          let value = payload.changedData;
          if (payload.changedDataRoleName === 'time') value = new Date(value);
          accountingMark[payload.changedDataRoleName] = value;
          break;
        }
      },
      remove_accountingMark (state: State, payload: any) {
        rowIterator: for (let i = 0; i < state.models.AccountingMarks!.length; i++) {
          for (let indexPart of payload) {
            let checkValue = state.models.AccountingMarks![i][indexPart.role];
            if (indexPart.role === 'time') checkValue = checkValue.getTime();
            if (checkValue !== indexPart.value) continue rowIterator;
          }
          state.models.AccountingMarks!.splice(i, 1);
          break;
        }
      },
      remove_all_accountingMark (state: State) {
        state.models.AccountingMarks!.length = 0;
      }
    }
    return mutations;
  }

  addRow (row: accountingMark): void {
    this.#store.commit('add_accountingMark', row);
  }

  updateRow (payload: UpdateRowPayload): void {
    this.#store.commit('update_accountingMark', payload);
  }

  removeRow (index: any): void {
    this.#store.commit('remove_accountingMark', index);
  }

  removeAll (): void {
    this.#store.commit('remove_all_accountingMark');
  }
}

export function createStore (rpcClient: RpcClient, socket: WebSocketAsPromised, customState: Record<string, any>, customMutations: any) : Store<State> {
  const models: Array<AbstractModel_Remote> = [];

  const WorkersModel = new WorkersModel_Remote(socket);
  models.push(WorkersModel);

  const OrdersModel = new OrdersModel_Remote(socket);
  models.push(OrdersModel);

  const TasksModel = new TasksModel_Remote(socket);
  models.push(TasksModel);

  const ActionTypesModel = new ActionTypesModel_Remote(socket);
  models.push(ActionTypesModel);

  const StockModel = new StockModel_Remote(socket);
  models.push(StockModel);

  const AverageDurationsModel = new AverageDurationsModel_Remote(socket);
  models.push(AverageDurationsModel);

  const WorkerStatisticksModel = new WorkerStatisticksModel_Remote(socket);
  models.push(WorkerStatisticksModel);

  const AccountingMarksModel = new AccountingMarksModel_Remote(socket);
  models.push(AccountingMarksModel);

  rpcClient.setModels(models);

  const emptyModelArrays = {};
  const mutations = customMutations;
  for (let model of models) {
    Object.assign(emptyModelArrays, model.getState());
    Object.assign(mutations, model.getMutations());
  }
  const state = customState;
  state.models = emptyModelArrays;

  // @ts-expect-error: We are sure that state is right
  const store = baseCreateStore<State>({ state, mutations });

  WorkersModel.setStore(store);

  OrdersModel.setStore(store);

  TasksModel.setStore(store);

  ActionTypesModel.setStore(store);

  StockModel.setStore(store);

  AverageDurationsModel.setStore(store);

  WorkerStatisticksModel.setStore(store);

  AccountingMarksModel.setStore(store);
  return store;
}
