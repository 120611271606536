import WebSocketAsPromised from 'websocket-as-promised'
import Channel from 'chnl';

// Server response and request

export interface RequestObject {
  // request to server specific actions comes without object prop
  object?: number
  method: number
  args?: Record<string, any>
}

interface ServerResponse_From {
  requestId: number
  response: any
}

interface ServerRequest_From {
  serverRequestId?: number
  request: RequestObject
}

// Response types

interface ModelFetchObject {
    roles: any
    indexRole: number
    data: Array<any>
}

// Abstract rpc objects

export abstract class AbstractObject_Remote {
    abstract objectId: number
    protected socket!: WebSocketAsPromised

    constructor (socket: WebSocketAsPromised) {
      this.socket = socket;
    }
}

export interface UpdateRowPayload {
  index: Array<{ role:string, value:any }>
  changedDataRoleName: string
  changedData: any
}

export abstract class AbstractModel_Remote extends AbstractObject_Remote {
  modelMethods = {
      
  }

  roleToRoleNameMap!: Map<number, string>
  modelInitialized = false
  modelUpdateMark = 0

    abstract getState () : any
    abstract getMutations () : any

    abstract addRow (row: any): void
    abstract updateRow (payload: UpdateRowPayload): void
    abstract removeRow (index: Array<{ role:string, value:any }>): void
    abstract removeAll (): void

    resetModel (modelData: Record<string, any>) {
      // clear existing model
      this.modelInitialized = false;
      this.removeAll();

      const rolesObj = modelData.roles;
      const dataArray = modelData.data;

      // populate role relation table
      this.roleToRoleNameMap = new Map()
      for (let role in rolesObj) {
        this.roleToRoleNameMap.set(+role, rolesObj[role]);
      }

      // populate model
      for (let row of dataArray) {
        this.addRow(this.unpackRowFromRelationMap(row));
      }

      this.modelInitialized = true;
    }

    unpackRowFromRelationMap (row: Record<string, any>) {
      const rowObject: Record<string, any> = {};
      for (let role in row) {
        rowObject[this.roleToRoleNameMap.get(+role) ?? ''] = row[role];
      }
      return rowObject;
    }
}

// Client

export const onAuthChanged = new Channel();
export const onConnectionChanged = new Channel();

export class RpcClient {
  RpcServer_Methods_To = {
    Login: 0,
    Logout: 1,
    SubscribeToModel: 2,
    UnsubscribeFromModel: 3
  }

  RpcServer_Methods_From = {
    UpdateModelData: 0,
    AddModelData: 1,
    RemoveModelData: 2,
    ResetModelData: 3
  }

  socket: WebSocketAsPromised
  isAuthenticated = false
  userName = ''
  userRole = 'Undefined'
  #password = ''
  isConnected = true
  protocolVersion = ''

  constructor (socket: WebSocketAsPromised) {
    this.socket = socket;

    this.socket.onClose.addListener(() => {
      if (this.isConnected) {
        this.isConnected = false;
        onConnectionChanged.dispatch({ isConnected: false });
      }
      this.connectToServer();
    });
    this.socket.onUnpackedMessage.addListener(this.updateModel.bind(this));
  }

  #rpcModels: Array<AbstractModel_Remote> = []
  #modelSubscriptionList: Array<number> = []

  setModels (models: Array<AbstractModel_Remote>): void {
    this.#rpcModels = models;
  }

  connectToServer () {
    this.socket.open().then(async () => {
      // try to login and refetch models in case of connection fault
      if (this.isAuthenticated) {
        const response = await this.login(this.userName, this.#password, this.protocolVersion);
        if (response.result) {
          this.reloadModelsAfterConnectionFail(response);
        }
      }
      this.isConnected = true;
      onConnectionChanged.dispatch({ isConnected: true });
    }).catch(() => console.log('Сервер недоступен'));
  }

  private async reloadModelsAfterConnectionFail (loginResponse: any) {
    let modelsToUpdate = this.#modelSubscriptionList.slice(0);

    for (let model of this.#rpcModels) {
      const index = modelsToUpdate.lastIndexOf(model.objectId);
      if (index === -1) continue; 
      for (let modelMark of loginResponse.modelMarks) {
        if (model.objectId !== modelMark.id) continue;
        if (model.modelUpdateMark === modelMark.mark) {
          modelsToUpdate.splice(index, 1);
        }
        break;
      }
    }

    const modelsToSubscribe = this.#modelSubscriptionList.slice(0);
    this.#modelSubscriptionList.length = 0;
    for (let modelId of modelsToSubscribe) {
      this.subscribeToModel(modelId, modelsToUpdate.lastIndexOf(modelId) !== -1);
    }
  }

  async subscribeToModel (objectId: number, dueToUpdate = true) {
    if (this.#modelSubscriptionList.lastIndexOf(objectId) !== -1) { return true; }

    this.#modelSubscriptionList.push(objectId);

    const requestObj: RequestObject = { method: this.RpcServer_Methods_To.SubscribeToModel, args: { modelId: objectId, fetchModel: dueToUpdate } };
    const response = (await this.socket.sendRequest(requestObj)).response;
    if (dueToUpdate) {
      if (response.result) {
        for (let model of this.#rpcModels) {
          if (model.objectId === objectId) {
            const resetModel = model.resetModel.bind(model);
            resetModel(response.modelData);
            model.modelUpdateMark = response.mark;
            break;
          }
        }
      } 
    }
  }

  async unSubscribeFromModel (objectId: number): Promise<boolean> {
    const index = this.#modelSubscriptionList.lastIndexOf(objectId);
    if (index === -1) { return true; }

    const requestObj: RequestObject = { method: this.RpcServer_Methods_To.UnsubscribeFromModel, args: { modelId: objectId } };
    const response = (await this.socket.sendRequest(requestObj)).response;
    if (response.result) { this.#modelSubscriptionList.splice(index, 1); }
    return response.result;
  }

  async login (name: string, pass: string, protocolVersion: string): Promise<{ result: boolean, error?: string }> {
    const requestObj: RequestObject = { method: this.RpcServer_Methods_To.Login, args: { name, pass, protocolVersion } };
    const response = (await this.socket.sendRequest(requestObj)).response;
    if (response.result) { 
      this.userName = name; 
      this.#password = pass; 
      this.isAuthenticated = true; 
      this.userRole = response.userRole; 
      this.protocolVersion = protocolVersion; 
      onAuthChanged.dispatch({ isAuthenticated: true, userName: name, userRole: response.userRole }); 
    } else { 
      console.log('Соединение восстановлено'); 
    }
    return response;
  }

  async logout (): Promise<{ result: boolean, error?: string }> {
    const requestObj: RequestObject = { method: this.RpcServer_Methods_To.Logout };
    const response = (await this.socket.sendRequest(requestObj)).response;
    if (response.result) { 
      this.#modelSubscriptionList.length = 0; 
      this.userName = ''; 
      this.#password = ''; 
      this.isAuthenticated = false; 
      this.userRole = ''; 
      this.protocolVersion = ''; 
      onAuthChanged.dispatch({ isAuthenticated: false, userName: '', userRole: '' }); 
    }
    // Whole page hard refresh to purge components cache
    // todo: soft reset with cached component destroy
    document.location.reload();
    return response;
  }

  private async updateModel (data: ServerRequest_From) {
    // skip client request answers -> we want to catch only model updates
    if (!data.hasOwnProperty('request')) return; // eslint-disable-line

    for (let model of this.#rpcModels) {
      if (model.objectId === data.request.object) {
        if ((!model.modelInitialized) && data.request.method !== this.RpcServer_Methods_From.ResetModelData) return;
        const request = data.request;
        switch (request.method) {
          case this.RpcServer_Methods_From.UpdateModelData: {
            const updateRow = model.updateRow.bind(model);
            for (let changedDataObject of request.args!.changedData) {
              updateRow({ index: request.args!.index, changedDataRoleName: model.roleToRoleNameMap.get(changedDataObject.role) ?? '', changedData: changedDataObject.value });
            }
            model.modelUpdateMark = request.args!.mark;
            break;
          }
          case this.RpcServer_Methods_From.AddModelData: {
            const addRow = model.addRow.bind(model);
            addRow(model.unpackRowFromRelationMap(request.args!.newData));
            model.modelUpdateMark = request.args!.mark;
            break;
          }
          case this.RpcServer_Methods_From.RemoveModelData: {
            const removeRow = model.removeRow.bind(model);
            removeRow(request.args!.index);
            model.modelUpdateMark = request.args!.mark;
            break;
          }
          case this.RpcServer_Methods_From.ResetModelData: {
            const resetModel = model.resetModel.bind(model);
            resetModel(request.args!.modelData);
            model.modelUpdateMark = request.args!.mark;
            break;
          }
        }
        break;
      }
    }
  }
}
