import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import { WebSocketRpc } from '@/WebSocket/WebSocketRpc'
import { key } from '@/generated/heplers/useStore'
import { RpcClient, onAuthChanged, onConnectionChanged } from '@/generated/heplers/apiAbstract'
import { createStore, State } from '@/generated/store'
import { createGlobalPropsAndProvideApi } from './generated/api'

// 0) If "local" cookie = true --> websocket to localhost
let local = false;
let cookies = document.cookie.split(';');
for (let i = 0; i < cookies.length; i++) {
  let pair = cookies[i].split('=');
  if (pair[0] === 'local' && pair[1] === '1') local = true;
}

// 1) Rpc client init
const url = local ? 'ws://localhost:1313' : 'wss://erp.woodenprint.ru/api:443';
// const url = 'ws://localhost:1313';
const wsp = new WebSocketRpc(url);
const client = new RpcClient(wsp);

// 2) Store creation with custom and model data
// create custom store data and typings through module augmentation
interface CustomStoreData{
  isConnected: boolean
  isAuthenticated: boolean
  userName: string
  userRole: string
}
declare module '@/generated/store' {
  interface State {
    custom: CustomStoreData
  }
}
const customData: CustomStoreData = { isConnected: false, isAuthenticated: false, userName: '', userRole: 'Undefined' };
// create custom store mutations
const customMutations = {
  authChanged (state: State, payload: { isAuthenticated: boolean, userName: string, userRole: string }) {
    state.custom.isAuthenticated = payload.isAuthenticated; state.custom.userName = payload.userName; state.custom.userRole = payload.userRole;
  },
  connectionChanged (state: State, payload: { isConnected: boolean}) {
    state.custom.isConnected = payload.isConnected;
  }
}
const store = createStore(client, wsp, { custom: customData }, customMutations);
// operate mutations if needed
onConnectionChanged.addListener(newConnection => store.commit('connectionChanged', { isConnected: newConnection.isConnected }));
onAuthChanged.addListener(newAuth => store.commit('authChanged', { isAuthenticated: newAuth.isAuthenticated, userName: newAuth.userName, userRole: newAuth.userRole }));

// 3) Main app creating
const app = createApp(App).use(store, key).use(router);

// 4) Inserting api objects and rpc client as global properties and provide for composition API
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $RpcClient: RpcClient
  }
}
app.config.globalProperties.$RpcClient = client; app.provide('RpcClient', client);
createGlobalPropsAndProvideApi(app, wsp);

// 5) Main app mounting
const vm = app.mount('#app');

// 6) Setup redirecting to login page
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !client.isAuthenticated) next({ name: 'Login' })
  else next()
})

// 7) Connect client to server
client.connectToServer();
