import { AbstractModel_Remote, AbstractObject_Remote, UpdateRowPayload, RequestObject } from './heplers/apiAbstract'
import { App } from 'vue'
import WebSocketAsPromised from 'websocket-as-promised';

export const protocolVersion = '1699351707400'

export enum ExposedObjectId {
  WorkTimeMarksApi_Id,
  WorkersApi_Id,
  TasksApi_Id,
  TaskMarksApi_Id,
  AccountingMarksApi_Id,
  WorkersModel_Id,
  OrdersModel_Id,
  TasksModel_Id,
  ActionTypesModel_Id,
  StockModel_Id,
  AverageDurationsModel_Id,
  WorkerStatisticksModel_Id,
  AccountingMarksModel_Id
}

// API objects
declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $WorkTimeMarksApi: WorkTimeMarksApi_Remote,
  $WorkersApi: WorkersApi_Remote,
  $TasksApi: TasksApi_Remote,
  $TaskMarksApi: TaskMarksApi_Remote,
  $AccountingMarksApi: AccountingMarksApi_Remote
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
enum WorkTimeMarksApiMethods {
  setMark
}
export class WorkTimeMarksApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.WorkTimeMarksApi_Id

  // Allowed roles: Worker, Manager, OperationalScreen, Executive
  setMark (name: string, mark: boolean, salaryRatePerHour: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: WorkTimeMarksApiMethods.setMark, args: { name: name, mark: mark, salaryRatePerHour: salaryRatePerHour } };
    this.socket.sendRequest(requestObj);
  }
}

// Allowed roles:
// Manager, Executive
enum WorkersApiMethods {
  updateUser,
  addUser
}
export class WorkersApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.WorkersApi_Id

  // Allowed roles: Manager, Executive
  updateUser (name: string, salaryRatePerHour: number, enabled: boolean, pass: string, role: string): void {
    const requestObj: RequestObject = { object: this.objectId, method: WorkersApiMethods.updateUser, args: { name: name, salaryRatePerHour: salaryRatePerHour, enabled: enabled, pass: pass, role: role } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Manager, Executive
  addUser (name: string, nameRu: string, salaryRatePerHour: number, enabled: boolean, pass: string, role: string): void {
    const requestObj: RequestObject = { object: this.objectId, method: WorkersApiMethods.addUser, args: { name: name, nameRu: nameRu, salaryRatePerHour: salaryRatePerHour, enabled: enabled, pass: pass, role: role } };
    this.socket.sendRequest(requestObj);
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
enum TasksApiMethods {
  newTask,
  markTaskLostAndUpdateAverageDuration,
  newBilletTasks,
  newBilletTaskType,
  setUnexpectedTaskApprovalAndUpdateWorkerProfits
}
export class TasksApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.TasksApi_Id

  // Allowed roles: Worker, Manager, OperationalScreen, Executive
  newTask (action: string, description: string, averageDurationSec: number, orderItemId: number, stockItemId: number, woodenSheetId: number, unexpectedTask: boolean, proposedDurationSec: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: TasksApiMethods.newTask, args: { action: action, description: description, averageDurationSec: averageDurationSec, orderItemId: orderItemId, stockItemId: stockItemId, woodenSheetId: woodenSheetId, unexpectedTask: unexpectedTask, proposedDurationSec: proposedDurationSec } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Worker, Manager, OperationalScreen, Executive
  markTaskLostAndUpdateAverageDuration (taskId: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: TasksApiMethods.markTaskLostAndUpdateAverageDuration, args: { taskId: taskId } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Manager, Executive
  newBilletTasks (action: string, count: number, differentDurationSec: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: TasksApiMethods.newBilletTasks, args: { action: action, count: count, differentDurationSec: differentDurationSec } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Manager, Executive
  newBilletTaskType (action: string, actionRu: string, averageDurationSec: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: TasksApiMethods.newBilletTaskType, args: { action: action, actionRu: actionRu, averageDurationSec: averageDurationSec } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Manager, Executive
  setUnexpectedTaskApprovalAndUpdateWorkerProfits (taskId: number, approval: boolean, approvalDurationMin: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: TasksApiMethods.setUnexpectedTaskApprovalAndUpdateWorkerProfits, args: { taskId: taskId, approval: approval, approvalDurationMin: approvalDurationMin } };
    this.socket.sendRequest(requestObj);
  }
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
enum TaskMarksApiMethods {
  addWorkerToTask,
  removeWorkerToTask,
  completeTask
}
export class TaskMarksApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.TaskMarksApi_Id

  // Allowed roles: Worker, Manager, OperationalScreen, Executive
  addWorkerToTask (taskId: number, worker: string): void {
    const requestObj: RequestObject = { object: this.objectId, method: TaskMarksApiMethods.addWorkerToTask, args: { taskId: taskId, worker: worker } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Worker, Manager, OperationalScreen, Executive
  removeWorkerToTask (taskId: number, worker: string): void {
    const requestObj: RequestObject = { object: this.objectId, method: TaskMarksApiMethods.removeWorkerToTask, args: { taskId: taskId, worker: worker } };
    this.socket.sendRequest(requestObj);
  }

  // Allowed roles: Worker, Manager, OperationalScreen, Executive
  completeTask (taskId: number): void {
    const requestObj: RequestObject = { object: this.objectId, method: TaskMarksApiMethods.completeTask, args: { taskId: taskId } };
    this.socket.sendRequest(requestObj);
  }
}

// Allowed roles:
// Executive
enum AccountingMarksApiMethods {
  transferMoneyToWorker
}
export class AccountingMarksApi_Remote extends AbstractObject_Remote {
  objectId = ExposedObjectId.AccountingMarksApi_Id

  // Allowed roles: Executive
  transferMoneyToWorker (workerName: string, money: number, comment: string): void {
    const requestObj: RequestObject = { object: this.objectId, method: AccountingMarksApiMethods.transferMoneyToWorker, args: { workerName: workerName, money: money, comment: comment } };
    this.socket.sendRequest(requestObj);
  }
}

export function createGlobalPropsAndProvideApi (app: App, socket: WebSocketAsPromised): void {
  //
  const WorkTimeMarksApi = new WorkTimeMarksApi_Remote(socket);
  app.config.globalProperties.$WorkTimeMarksApi = WorkTimeMarksApi; app.provide('WorkTimeMarksApi', WorkTimeMarksApi);
  const WorkersApi = new WorkersApi_Remote(socket);
  app.config.globalProperties.$WorkersApi = WorkersApi; app.provide('WorkersApi', WorkersApi);
  const TasksApi = new TasksApi_Remote(socket);
  app.config.globalProperties.$TasksApi = TasksApi; app.provide('TasksApi', TasksApi);
  const TaskMarksApi = new TaskMarksApi_Remote(socket);
  app.config.globalProperties.$TaskMarksApi = TaskMarksApi; app.provide('TaskMarksApi', TaskMarksApi);
  const AccountingMarksApi = new AccountingMarksApi_Remote(socket);
  app.config.globalProperties.$AccountingMarksApi = AccountingMarksApi; app.provide('AccountingMarksApi', AccountingMarksApi);
}

// API models

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// role: Worker -> column: name, role: Manager -> column: name
export abstract class WorkersModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WorkersModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export abstract class OrdersModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.OrdersModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export abstract class TasksModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.TasksModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export abstract class ActionTypesModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.ActionTypesModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export abstract class StockModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.StockModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, OperationalScreen, Manager, Executive
// Row restriction based on role and user name:
// 
export abstract class AverageDurationsModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.AverageDurationsModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, Manager, Executive
// Row restriction based on role and user name:
// role: Worker -> column: name, role: Manager -> column: name
export abstract class WorkerStatisticksModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.WorkerStatisticksModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}

// Allowed roles:
// Worker, Manager, Executive
// Row restriction based on role and user name:
// role: Worker -> column: account_name, role: Manager -> column: account_name
export abstract class AccountingMarksModel_Remote extends AbstractModel_Remote {
  objectId = ExposedObjectId.AccountingMarksModel_Id

  abstract getState () : any
  abstract getMutations () : any

  abstract addRow (row: any) : void
  abstract updateRow (payload: UpdateRowPayload) : void
  abstract removeRow (index: any): void
  abstract removeAll (): void
}
